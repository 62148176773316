import React from "react"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import Background from "../../../assets/images/servicesHeader/sem_icon.svg"
import arrow from "../../../assets/images/servicesPages/arrowDown.png"
import sem_group from "../../../assets/images/servicesPages/sem-group.svg" 

export default () => (
    <div>
        <SEO title={'ICON Worldwide | Search Engine Marketing Services'} 
        description="Google Ads, Social and PPC campaigns in-target for all your platforms, to gain the online visibility you deserve: check ICON Worldwide's SEM services!"
        canonical={'https://icon-worldwide.com/services/search-engine-marketing'}/>
        <Header headerTitle="Search Engine Marketing" headerSubTitle="Personalized, in-target Google Adwords and PPC campaigns"  headerBackground={Background}/>
        <div id="servicesPagesInner">
            <div className="winningTeam">
                <div className="text">
                    <h2>Google Adwords <span>and</span> PPC campaigns <span>on platforms such as Twitter, Instgram, LinkedIn and
                    Facebook are an</span> essential tool to gain visibility online.
                    </h2>
                    <ul>
                    <li>We set realistic Google AdWords goals based on your unique needs and budget</li>
                    <li>Identify the audiences and demographics your business needs to target </li> 
                    <li>Create targeted keywords and smart bids for best results</li>
                    <li>Continuously optimise, adjust based on real-time performance</li>
                    </ul>
                </div>
            </div>
            <img className="arrowDown" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <div className="normal-image">
            <div className="text">
                <h2>Our POV</h2>
                <ul>
                    <li><h3>Correlations exist between paid and organic</h3></li>
                    <p>PPC budget must be planned as part of the site development process</p>
                    <li><h3>PPC is a relatively minor cost compared to the initial design and development budget</h3></li> 
                    <p>PPC and site development are interdependent</p>
                    <li><h3>PPC ensures your target is reached</h3></li>
                    <li><h3>Smart UI/UX design and good code compels the user to complete a specific action</h3></li>
                    <p>Display advertising is a great way to achieve visibility on high traffic platforms</p>
                </ul>  
                </div>
            </div>

            <div className="twoColumns">
                <div className="block-two">
                    <ul className="bigger-ul">
                        <li>Expert Strategy Planning & Execution  </li>
                        <li>Ad Creation & Optimization  </li> 
                        <li>In-Depth Keyword Discovery</li>
                        <li>Regular Campaign Progress Reporting </li> 
                        <li>Discovery Of New Market & Medium Opportunities</li>
                        <li>Thorough Analysis Of Your Industry Competitors  </li> 
                        <li>Roi Measurements & Optimization Tips </li>
                        <li>Ongoing Campaign Tracking And Monitoring  </li> 
                        <li>Advanced Campaign Consulting</li>
                        <li>Geo-targeting And Day-parting)</li>
                    </ul>
                </div>

                <div className="block-two">
                    <img src={sem_group} alt="sem services, ICON Worldwide, maximising digital roi" title="ICON Worldwide, sem services, maximising digital roi"/>
                </div>
            </div>
            <div className="yellowLine"></div>
            <ServicesBottomNav/>
            </div>
        <Footer noScrollbar="true"/>
    </div>
)