import React from "react"
import { Link } from "gatsby"
import ServiceMenu from "./servicesMenuHeader"

export default props => (
    <div>
    <div id="serivces-header">
    <ServiceMenu/>
        <div id="background"><img src={props.headerBackground} alt="backgroundIcon"/></div>
        <div id="headerInner">
            <h1>{props.headerTitle}</h1>
            <div className="headerLine"></div>
            <h2>{props.headerSubTitle}</h2>
            <Link to="/services" className="serviceBtn">All Services</Link>
        </div>
    </div>
    </div>
);